<template>

   <div v-if="is_loading">
   <ol class="breadcrumb">
      <li class="breadcrumb-item">Trang chủ</li>
      <li class="breadcrumb-item">
       
        <router-link to="/admin/booth/lists"> Quản lý Mặt bằng </router-link>
      </li>

      <li class="breadcrumb-item">Import Mặt bằng </li>
    </ol>
    <div class="container-fluid">
      
      

      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header">
                
            </div>
           
            <div class="card-block">
              
  <div>
 
    <section>
      <h3> Import Excel 

       </h3>
      <input type="file" @change="onChange" class="form-control" 
       accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" 
      />

      <br />
      <p> <a href="/layout/Quan_Ly_Mat_Bang.xlsx" target="_black">Mẫu CSV</a> 
       <p> Xem Trước dữ liệu  </p>

     

       <xlsx-read :file="file" >
        <template #default="{loading}">
          <span v-if="loading">Loading...</span>
          <div v-else > 

                <xlsx-sheets>


                  <template #default="{sheets}"  >
                    <select v-model="selectedSheet" class="form-control">
                      <option v-for="sheet in sheets" :key="sheet" :value="sheet">
                        {{ sheet }}
                      </option>
                    </select>
                  </template>
                </xlsx-sheets>
                <xlsx-table :sheet="selectedSheet"  />

                <xlsx-json :sheet="selectedSheet" style="display: none;">
                  <template #default="{collection}">
                    {{collection}}
                    <div>
                      {{ getCollection(collection) }}
                    </div>
                   
                  </template>
                </xlsx-json>
          </div>
        </template>
      </xlsx-read>

      
    </section>
  </div>
   <br />
   <button
              type="submit"
              class="btn btn-sm btn-primary"
              @click="ImportToDatabase"
              
            >
              <i class="fa fa-save"></i> Lưu dữ liệu 
            </button>
            </div>
          </div>
        </div>
        <!--/.col-->
      </div>
    </div>
  </div>
  <div v-else> 
      <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12">
                  <MyLoading  v-for="x in 10" :key="x" height="40px" width="100%" ></MyLoading>
             </div>
          </div>
     </div>
  </div>

</template>

<script>
import { XlsxRead, XlsxTable, XlsxSheets, XlsxJson } from "vue-xlsx";

import axios from "axios";

export default {
  components: {
    XlsxRead,
    XlsxTable,
    XlsxSheets,
    XlsxJson
   
   
  },
  data() {
    return {
      is_loading:true,
      file: null,
      selectedSheet: null,
      sheetName: null,
      sheets: [],
      collection: [],
      loading:false,
      data:null,
      location:null,
       json_fields: ['name','schedule','timer'],
              
    };
  },
  created(){
      
  },
  methods: {
     getCollection(data){
        this.data=data;
     },
    onChange(event) {
      this.file = event.target.files ? event.target.files[0] : null;
    },
    addSheet() {
      this.sheets.push({ name: this.sheetName, data: [...this.collection] });
      this.sheetName = null;
    },
    ImportToDatabase(){
      this.is_loading=false;

      let my_promise=[];
      for (var i = this.data.length - 1; i >= 0; i--) {
         //console.log(Object.values(this.data[i]),Object.entries(this.data[i]));
         let DataForm=[];
         for (const [k , value] of Object.entries(this.data[i])) {
           if(k!=null){
              DataForm.push(value);
           }
            
            
          }

      

        if(DataForm[0].length>-1 && DataForm[1].length>-1 && DataForm[7] != undefined ){
                 let formData = new FormData();
                
                 formData.append("area",DataForm[0]);
                 formData.append("boothName",DataForm[1]);
              
                 formData.append("ranger",DataForm[2]==undefined ? "":DataForm[2]);
                 formData.append("height",DataForm[3]==undefined ? "":DataForm[3]);
                 formData.append("width",DataForm[4]==undefined ? "":DataForm[4]);

                 formData.append("acreage",DataForm[5]==undefined ? "":DataForm[5]);
                 formData.append("content",DataForm[6]==undefined ? "":DataForm[6]);
                 formData.append("descriptionStatus",DataForm[7]==undefined ? "":DataForm[7]);

                 formData.append("price",DataForm[8]==undefined ? "":DataForm[8]);
                 formData.append("contact",DataForm[9]==undefined ? "":DataForm[9]);
                 formData.append("leaseProcedure",DataForm[10]==undefined ? "":DataForm[10]);
               

                 formData.append("type",'0'); 

                formData.append("Area_EN", formData.get('area'));
                formData.append("Ranger_EN", formData.get('ranger'));
            
                formData.append("Width_EN", formData.get('width'));
                formData.append("Height_EN", formData.get('height'));
                formData.append("Acreage_EN", formData.get('acreage'));
                formData.append("content_EN", formData.get('content'));
                formData.append("descriptionStatus_EN", formData.get('descriptionstatus'));

                formData.append("price_EN", formData.get('price'));
                formData.append("contact_EN", formData.get('contact'));
                formData.append("leaseProcedure_EN", formData.get('leaseProcedure'));


                 console.log(DataForm,formData);

              let t= axios
                   .post(window.my_api + "api/booth/create-or-update-booth", formData,
                      {
                      headers: window.my_header
                    }
                  )
                  .then((res) => {

                    if (res.status == 200) {

                          console.log(res);
                    

                    } else {
                      alert("Thêm không thành công, vui lòng liên hệ admin");
                     
                    }
                    
                  });


                  my_promise.push(t);

                  

          }
      }
      Promise.all(my_promise).then(()=>{
                    alert("Cập nhật dữ liệu Excel thanh công ");
          this.is_loading=true;
      });
        

    },
    GetLocation(name){

        var result= this.location.filter( (n)=> n.name.toUpperCase().search(name.toUpperCase()) > -1  );
        if(result.length){
            return result[0].id;
        }
        return "";


    }
  }
};
</script>
<style type="text/css">
  table{
    width: 100%;
  }

  table td,table th{
    border: 1px solid #ddd;
    padding: 10px;
    min-height: 50px;
  }
</style>